import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import SbEditable from "storyblok-react"
import DynamicComponent from "../components/DynamicComponent"
import useStoryblok from "../utils/storyblok"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import AndroidTerms from "../components/android/AndroidTerms"

const AppAgreementAndroid = ({ data, location }) => {
  return (
    <Layout>
      <Seo title="Android Agreement" pageName="app-agreement-android" />
      <AndroidTerms />
    </Layout>
  )
}

export default AppAgreementAndroid

export const query = graphql`
  query AndroidQuery {
    storyblokEntry(full_slug: { eq: "app-agreement-android" }) {
      content
      name
    }
  }
`
