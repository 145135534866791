import React from "react"

import {
  InboxIcon,
  SparklesIcon,
  ShoppingCartIcon,
} from "@heroicons/react/outline"

/* eslint-disable */
const AndroidTerms = () => (
  <section className="section">
    <h1 className="h1-heading text-4xl pt-4 pb-4">Privacy Policy</h1>
    <h2 className="h2-heading text-2xl pb-4">Introducing Shopsmart</h2>
    <small className="text-sm pb-4">Last updated August 11, 2018</small>
    <p className="pb-4 pt-4">
      Shopsmart, an Australian Company (referred to in this Privacy Policy as
      shopsmart.com.au, Shopsmart or Shopsmart App) understands and respects
      your privacy and is committed to your protection through compliance with
      this policy.{" "}
    </p>
    <p className="pb-4">
      This policy describes our privacy practices for our websites
      (shopsmart.com.au, shopsmart.ai), any mobile apps we distribute that refer
      to this policy, and the Shopsmart Service, if you register for it (the
      “Service”). This policy does not apply to information we might collect in
      other situations, like offline or by email. This policy does not apply to
      the practices of third parties who may provide services or features based
      on our website or the Service, including third parties for whom Shopsmart
      hosts websites that are served to web clients, including compatible
      browsers, scanners, and apps (“Hosted Websites”). These Hosted Websites
      are commonly, but not exclusively, viewed on mobile devices in proximity
      to location‑identifying data transmitters, including QR codes, NFC tags,
      Bluetooth Low Energy (BLE) beacons, and data augmented audio signals.
    </p>
    <p className="pb-4">
      Please ensure you take the time to read this policy carefully to
      understand our policies and practices regarding your information and how
      we will treat it. If you do not agree with our policies and practices, do
      not use our website or register with or use the Service. By using our
      website or registering with or using the Service, you agree to this
      privacy policy. This policy may change from time to time. Your continued
      use after we make changes is deemed to be acceptance of those changes, so
      please check the policy periodically for updates.
    </p>
    <h3 className="text-lg pb-4">
      Information We Collect and How We Collect It{" "}
    </h3>
    <ol type="a" className="pt-4 pb-4">
      <li className="pb-4">
        <span className="terms-small-headings">
          Information You Provide to Us.
        </span>
        We may receive and store any information you enter on our website or the
        Service or provide to us in any other way. Included in this information
        may be personal information by which you may be identified, such as
        name, phone number, company name, mailing address, billing information,
        and email address.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">Information Collection.</span>
        When you access and use our website or the Service, it may use
        technology to automatically collect the following:
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">Website Details.</span>
        We may collect information about the parts of our website or the Service
        you use. We may work with third parties who collect information about
        you when you use our website or the Service.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">Service Usage Details.</span>
        When you access and use the Service, we may automatically collect
        certain details of your access to and use, including location data and
        other communication data and the resources that you access and use on or
        through the Service.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">Location Information.</span>
        We may collect real-time information about the location of your device
        to provide you the Service and to make it easier for you to use the
        Service. <br />
        Other information. We may collect information about your IP address,
        operating system, browser type, and mobile network.
      </li>
    </ol>
    <h3 className="text-lg pt-4 pb-4">
      Cookies and Information Collection Technologies{" "}
    </h3>
    <p className="pb-4">
      The technologies we use for automatic information collection may include:{" "}
    </p>
    <ol className="pt-4 pb-4">
      <li className="pb-4">
        <span className="terms-small-headings">
          Cookies (or mobile cookies).
        </span>
        A cookie is a small file placed on your computer or mobile device. Your
        browser may give you the ability to reject cookies. If you choose to do
        so, some parts of our website and the Service may be more difficult or
        impossible to use. If you do not want us to collect information about
        you or your device, do not use our website or register for or use the
        Service.
      </li>
    </ol>
    <h3 className="text-lg pb-4"> How We Use Your Information </h3>
    <p className="pb-4">
      We use information that we collect about you or that you provide to us,
      including any personal information, as follows:{" "}
    </p>
    <ol className="pt-4 pb-4">
      <li className="pb-4">
        <span className="terms-small-headings">
          We use information to respond to your requests or questions.{" "}
        </span>
        For example, we may use your information to help you access your account
        if you forget your password.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">
          We use your information to help us improve our products and services.{" "}
        </span>
        We may use information to customize and improve your experience. For
        example, we might look at usage trends to make sure our website and the
        Service are easy to use.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">For security purposes. </span>
        We may use information to protect our company, our customers, our
        website, or the Service.
      </li>

      <li className="pb-4">
        <span className="terms-small-headings">To provide updates. </span>
        We may send you email or in-product communications about your account,
        transactions, or changes to our website, the Service, or policies.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">
          For our legitimate business purposes.{" "}
        </span>
        We may use your information to fulfil any other purpose for which you
        provide it. We may also use information you provide to carry out our
        obligations and enforce our rights arising from any contracts entered
        into between you and us, including for billing and collection. We may
        combine information that we receive about you from third parties with
        information we already have. <br />
        <br />
        <strong className="pt-4">As otherwise permitted by law.</strong>
      </li>
    </ol>
    <h3 className="text-lg pb-4">Disclosure of Your Information </h3>
    <p className="pb-4">
      We may disclose aggregated information about our users without
      restriction. In addition, we may disclose personal information that we
      collect, or you provide in the following ways (subject to the provisions
      below regarding customers in the European Union, as applicable):{" "}
    </p>
    <ol className="pt-4 pb-4">
      <li className="pb-4">
        <span className="terms-small-headings">Service Providers. </span>
        We share information with contractors, service providers and other third
        parties we use to support our business.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">
          Change in corporate ownership.{" "}
        </span>
        We share information with a buyer or other successor in the event of a
        merger, divestiture, restructuring, reorganization, dissolution or other
        sale or transfer of some or all of Shopsmart’s assets, whether as a
        going concern or as part of bankruptcy, liquidation or similar
        proceeding, in which personal information is among the assets
        transferred.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">
          To protect rights and safety.{" "}
        </span>
        If we believe disclosure is necessary or appropriate to protect the
        rights, property, or safety of Shopsmart, our customers or others.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">For legal purposes. </span>
        To comply with any court order, law or legal process, including to
        respond to any government or regulatory request.
      </li>
    </ol>
    <strong className="pt-4 pb-4">
      To our subsidiaries and affiliates.{" "}
    </strong>{" "}
    <br />
    <strong className="pt-4 pb-4">
      For any other purpose we disclose when you provide the information.{" "}
    </strong>{" "}
    <br />
    <strong className="pt-4 pb-4">Otherwise with your consent. </strong> <br />
    <h3 className="text-lg pt-4 pb-4"> Data Security </h3>
    <ol className="pt-4 pb-4">
      <li className="pb-4">
        We have implemented measures designed to secure your personal
        information from accidental loss and from unauthorized access, use,
        alteration and disclosure. However, we cannot promise that your
        information will remain secure. We encourage you to use caution when
        sharing information. Do not share your password with other people and
        pick secure passwords.
      </li>
      <li className="pb-4">
        Our sites and servers may be located in Australia and the United States.
        If you are outside of the U.S., your information may be sent to the
        United States. By using our sites and applications, and by giving us
        your information, you consent to such transfer. You also understand that
        the U.S. may not provide you with as much protections as the laws of
        your country.
      </li>
      <li className="pb-4">
        We offer customers in the European Union (EU) a data processing addendum
        incorporating the European Commission’s standard contractual clauses,
        commonly referred to as “model clauses,” for EU personal data. If you
        wish to execute the addendum, contact us at admin@shopsmart.ai. We use
        services of other companies in providing the Service. When provision of
        their services includes processing EU personal data, we require them to
        execute agreements that contain the model clauses.
      </li>
      <li className="pb-4">
        The safety and security of your information also depends on you. Where
        we have given you (or where you have chosen) a PIN or password for
        access to certain parts of our application, you are responsible for
        keeping this information confidential. We ask you not to share this
        information with anyone.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">
          Children under the Age of 18{" "}
        </span>
        Our platform is not intended for children under 18 years of age, and we
        do not knowingly collect personal information from children under 18.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">Third-Parties </span>
        Our website and the Service may contain links to third-party sites. If
        you click on one of those links, you will be taken to a site we do not
        control. This policy does not apply to the practices of that site. You
        should read third-party privacy policies carefully.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">
          Changes to Our Privacy Policy{" "}
        </span>
        We may update our privacy policy from time to time. If we make material
        changes to how we treat our users’ personal information, we will post
        the new privacy policy on this page.
      </li>
      <li className="pb-4">
        <span className="terms-small-headings">
          Accessing and Correcting Your Personal Information{" "}
        </span>
        You can review and change your personal information by logging in and
        visiting your account profile page. To ask questions or comment about
        this privacy policy and our privacy practices, contact us at hello at
        shopsmart dot ai
      </li>
    </ol>
  </section>
)

export default AndroidTerms
/* eslint-enable */
console.log("terms")
